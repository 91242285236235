import clsx from "clsx"
import type { Locale } from "date-fns"
import type React from "react"
import {
	type ForwardedRef,
	type MutableRefObject,
	type ReactNode,
	type SyntheticEvent,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react"
import DatePicker, { CalendarContainer, type DatePickerProps, registerLocale } from "react-datepicker"
import { Input, type InputProps } from "./input"
import { Portal } from "./portal"

import parseISO from "date-fns/parseISO"
import "react-datepicker/dist/react-datepicker.css"
import { CalendarIcon } from "../icons/calendar-icon"

import enUS from "date-fns/locale/en-US"
import es from "date-fns/locale/es"
import fr from "date-fns/locale/fr"

import { getCompletedPartialDate } from "@allocine/common-utils"
import { isEmpty } from "../utils/check"
import { IconButton } from "./button"
import {
	type DateFormat,
	DateFormatsRevampedEnum,
	type DatePickerRevampedSupportedLocale,
	PARTIAL_DATE_YEAR_FORMATS,
	PARTIAL_DATE_YEAR_MONTH_FORMATS,
	allEnDateFormats,
	allLatinDateFormats,
	dateFormatLocalized,
	dateFormatLocalizedPlaceholders,
	dateTransformer,
	defaultLocale,
	enDateFormatLocalized,
	isoTransformer,
	isoWithoutTZTransformer,
	latinDateFormatLocalized,
	partialDateTransformer,
	timeWithoutTZTransformer,
} from "./date-picker-revamped-common"
import s from "./date-picker-revamped.module.css"

const localesMapping: {
	[k in DatePickerRevampedSupportedLocale]: Locale
} = {
	fr,
	"fr-FR": fr,
	es,
	"es-MX": es,
	"en-US": enUS,
	[defaultLocale]: enUS,
}

for (const [localeK, locale] of Object.entries<Locale>(localesMapping)) registerLocale(localeK, locale)

const getCompletedInputValue = (dateFormat: string, targetValue: string) => {
	const separator = dateFormat.includes("/") ? "/" : "-"
	const newVal = targetValue.slice(0, dateFormat.length) // restrict extra characters to the expected format

	// specific case of years, only return this part in all cases
	if (
		[
			latinDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR],
			enDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR],
		].includes(dateFormat)
	) {
		return newVal
	}

	if (allLatinDateFormats.includes(dateFormat)) {
		// specific case of month + year
		if (dateFormat === latinDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]) {
			if (newVal.match(/^\d{3}$/g)) {
				const begin = newVal.slice(0, 2)
				const next = newVal.slice(2, 4)
				return `${begin}${separator}${next}`
			}
			if (newVal.match(/^\d{2}\/\d{1,4}$/g)) return newVal
		}

		// full size
		if (newVal.match(/^\d{3}$/g)) {
			const begin = newVal.slice(0, 2)
			const next = newVal.slice(2, 4)
			return `${begin}${separator}${next}`
		}
		if (newVal.match(/^\d{2}\/\d{3}$/g)) {
			const begin = newVal.slice(0, 5)
			const next = newVal.slice(5, 7)
			return `${begin}${separator}${next}`
		}
		if (newVal.match(/^\d{2}\/\d{2}\/\d{5}$/g)) {
			const begin = newVal.slice(0, 10)
			const next = newVal.slice(10, 12)
			return `${begin}${" "}${next}`
		}
		if (newVal.match(/^\d{2}\/\d{2}\/\d{4} \d{3}$/g)) {
			const begin = newVal.slice(0, 13)
			const next = newVal.slice(13, 15)
			return `${begin}${":"}${next}`
		}
	}
	if (allEnDateFormats.includes(dateFormat)) {
		// specific case of month + year
		if (dateFormat === enDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]) {
			if (newVal.match(/^\d{5}$/g)) {
				const begin = newVal.slice(0, 4)
				const next = newVal.slice(4, 6)
				return `${begin}${separator}${next}`
			}
			if (newVal.match(/^\d{4}-\d{1,2}$/g)) return newVal
		}

		// full size
		if (newVal.match(/^\d{5}$/g)) {
			const begin = newVal.slice(0, 4)
			const next = newVal.slice(4, 6)
			return `${begin}${separator}${next}`
		}
		if (newVal.match(/^\d{4}-\d{3}$/g)) {
			const begin = newVal.slice(0, 7)
			const next = newVal.slice(7, 9)
			return `${begin}${separator}${next}`
		}
		if (newVal.match(/^\d{4}-\d{2}-\d{3}$/g)) {
			const begin = newVal.slice(0, 10)
			const next = newVal.slice(10, 12)
			return `${begin}${" "}${next}`
		}
		if (newVal.match(/^\d{4}-\d{2}-\d{2} \d{3}$/g)) {
			const begin = newVal.slice(0, 13)
			const next = newVal.slice(13, 15)
			return `${begin}${":"}${next}`
		}
	}
	return newVal
}

const DatePickerRevampedCustomInput = forwardRef<
	HTMLInputElement,
	InputProps & {
		inputRef: MutableRefObject<HTMLInputElement | null>
		partial?: boolean
		autoFormat?: boolean
		dateFormat: string
		onClick?: () => void
		doFocus: () => void
		open: boolean
	}
>(({ open, inputRef, className, disabled, dateFormat, autoFormat, doFocus, ...rest }, _ref) => (
	<div className={"flex"}>
		<div className="flex justify-start mr-1">
			<IconButton
				type="button"
				color={open ? "info" : "inactive"}
				tabIndex={-1}
				disabled={disabled}
				className={clsx("mr-1 flex justify-center items-center")}
				onClick={() => {
					doFocus()
				}}
			>
				<CalendarIcon />
			</IconButton>
		</div>
		<Input
			{...rest}
			{...(autoFormat
				? {
						onChange: (e) => {
							const newEv = {
								...e,
							}
							newEv.target.value = getCompletedInputValue(dateFormat, newEv.target.value)
							rest.onChange?.(newEv)
						},
					}
				: {})}
			ref={inputRef}
			disabled={disabled}
			autoComplete="off"
			type="text"
			className={clsx("w-full", className)}
		/>
	</div>
))

const DatePickerRevampedPopperContainer = ({ className, children }: { className?: string; children?: ReactNode }) => (
	// z-[51] to be above the modal backdrop, relative is needed too in this case
	<Portal className="z-[51] relative">
		<CalendarContainer className={clsx(className, "drop-shadow-xl")}>
			<div>{children}</div>
		</CalendarContainer>
	</Portal>
)

type ISODatePickerReturn = string | null
type OriginalDatePickerReturn = Date | null

export type DateTransformerFn<R extends Date | string = string> = (
	date: Date | string | null | undefined,
) => R | null | undefined

export type DatePickerRevampedProps<R extends Date | string = string> = Omit<
	DatePickerProps,
	| "onChange"
	| "onSelect"
	| "locale"
	| "minDate"
	| "maxDate"
	| "value"
	| "defaultValue"
	| "dateFormat"
	| "customInput"
	| "selectsRange"
	| "selectsMultiple"
	| "excludeScrollbar"
> & {
	value?: R | null
	defaultValue?: R | null
	partial?: boolean // support for partial date such as 2024,2024-03,2024-03-12
	dateOnly?: boolean // date-only (no time)
	isoDate?: boolean // serialized to ISO date format in onChange
	isoDatetimeWithoutTZ?: boolean // serialized to ISO date format without Timezone in onChange
	timeWithoutTZ?: boolean // serialized to HH:MM:SS
	autoFormat?: boolean // let user type only numbers, the picker takes care of -,/,: characters
	autoCloseOnComplete?: boolean // close the picker when the date is fully typed (equals to the required format)
	dateFormat?: DateFormat | string // format needed for this picker
	minDate?: Date | string | null
	maxDate?: Date | string | null
	invalid?: boolean
	disabled?: boolean
	detectLocale?: boolean // detect the locale from the browser
	locale?: DatePickerRevampedSupportedLocale // override the detected locale if needed
	onChange?: (
		date: R extends string ? ISODatePickerReturn : OriginalDatePickerReturn,
		// biome-ignore lint/suspicious/noExplicitAny: same signature as in lib except for date param
		event: SyntheticEvent<any> | undefined,
	) => void
	transformer?: DateTransformerFn<R> // transform the outputted date
	onSelect?: DatePickerProps["onSelect"]
	selectsRange?: boolean
	selectsMultiple?: boolean
	excludeScrollbar?: boolean
}

const getFormats = <R extends Date | string = string>({
	dateFormat: pDateFormat = DateFormatsRevampedEnum.DATE,
	value,
	defaultValue,
	selected,
	locale: pLocale,
	partial = false,
	detectLocale = false, // set this one to true when ready to prod
	expectedFormat,
}: Pick<
	DatePickerRevampedProps<R>,
	"dateFormat" | "locale" | "detectLocale" | "value" | "defaultValue" | "selected" | "partial"
> & {
	expectedFormat?: DateFormatsRevampedEnum | string
}) => {
	let locale = pLocale
	if (detectLocale && !locale && navigator.language in localesMapping)
		locale = navigator.language as DatePickerRevampedSupportedLocale

	// fallbacks to en_US ==> en, es-MX ==> es, fr-FR ==> fr
	if (locale && !(locale in localesMapping)) {
		const splitted = locale.split("-")
		if (splitted.length > 1) {
			const localeK = splitted[0] as DatePickerRevampedSupportedLocale
			if (localeK in localesMapping) locale = localeK
		}
	}
	if (!locale) locale = defaultLocale

	let dateFormat: string | undefined = undefined
	let dateFormatPlaceholder: string | undefined = undefined
	let detectedFormatFromValue: string | undefined = undefined
	if (locale && typeof locale === "string" && locale in dateFormatLocalized) {
		const locDateFormat = dateFormatLocalized[locale as DatePickerRevampedSupportedLocale]
		const locDateFormatPlaceholder = dateFormatLocalizedPlaceholders[locale as DatePickerRevampedSupportedLocale]
		if (pDateFormat in locDateFormat) {
			dateFormat = locDateFormat[pDateFormat as DateFormat]
			dateFormatPlaceholder = locDateFormatPlaceholder[pDateFormat as DateFormat]
		}
		if (expectedFormat && expectedFormat in locDateFormat) {
			dateFormat = locDateFormat[expectedFormat as DateFormat]
			dateFormatPlaceholder = locDateFormatPlaceholder[expectedFormat as DateFormat]
		}
	}
	if (!dateFormat && expectedFormat) dateFormat = expectedFormat
	if (!dateFormat) dateFormat = pDateFormat
	if (!dateFormatPlaceholder) dateFormatPlaceholder = dateFormat

	// compute detected format from initial value
	const separator = dateFormat.includes("/") ? "/" : "-"
	if (partial) {
		let v = value ?? defaultValue ?? selected ?? ""
		if (v) {
			if (v instanceof Date) v = v.toISOString()
			const splitted = v.split(separator)
			if (splitted.length === 2) detectedFormatFromValue = DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH
			else if (splitted.length === 1) detectedFormatFromValue = DateFormatsRevampedEnum.PARTIAL_DATE_YEAR
		}
	}
	if (!detectedFormatFromValue) detectedFormatFromValue = dateFormat

	return {
		originalDateFormat: pDateFormat,
		detectedFormatFromValue,
		dateFormat,
		dateFormatPlaceholder,
		locale,
		separator,
	}
}

export type DatePickerRevampedHTMLInputRef = {
	focus: () => void
	blur: () => void
	hasFocus: () => boolean
	setSelectedDateFromDate: (v: Date) => void
}

const _DatePickerRevamped = <R extends Date | string = string>(
	props: DatePickerRevampedProps<R>,
	ref: ForwardedRef<DatePickerRevampedHTMLInputRef | null>,
) => {
	const {
		partial = false,
		dateOnly = false,
		isoDate = false,
		isoDatetimeWithoutTZ = false,
		timeWithoutTZ = false,
		showTimeSelect: pShowtimeSelect,
		value: pValue,
		defaultValue: pDefaultValue,
		isClearable = true,
		selected,
		invalid,
		minDate: pMinDate,
		maxDate: pMaxDate,
		onChange,
		transformer: pTransformer,
		autoFormat = true,
		autoCloseOnComplete = true,
		open: pOpen = false,
		excludeScrollbar = false,
		...rest
	} = props

	if (Array.isArray(props.dateFormat))
		throw new Error("DatePickerRevamped:dateFormat is invalid, only single format is allowed")

	const { originalDateFormat, dateFormat, detectedFormatFromValue, dateFormatPlaceholder, locale, separator } = useMemo(
		() => getFormats(props),
		[props],
	)
	const [dynamicFormat, setDynamicFormat] = useState<string>(detectedFormatFromValue)

	let transformer = pTransformer as DateTransformerFn<R>
	if (!pTransformer) {
		if (partial) transformer = partialDateTransformer(dynamicFormat) as DateTransformerFn<R>
		else if (dateOnly) transformer = dateTransformer as DateTransformerFn<R>
		else if (isoDate) transformer = isoTransformer as DateTransformerFn<R>
		else if (isoDatetimeWithoutTZ) transformer = isoWithoutTZTransformer as DateTransformerFn<R>
		else if (timeWithoutTZ) transformer = timeWithoutTZTransformer as DateTransformerFn<R>
	}

	// default transformer to iso
	if (!(partial || dateOnly || isoDate || isoDatetimeWithoutTZ || timeWithoutTZ || pTransformer))
		transformer = isoTransformer as DateTransformerFn<R>

	const [open, setOpen] = useState<boolean>(pOpen)

	const [placeholder, setPlaceholder] = useState<string>(dateFormatPlaceholder)
	const [changedValue, setChangedValue] = useState<Date | null | undefined>(selected) // keep track of the changed value for onKeyDown handler (such as hit of enter)
	const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(selected)

	const inputRef = useRef<HTMLInputElement | null>(null)
	const calendarRef = useRef<DatePicker | null>(null)

	const doFocus = useCallback(() => {
		calendarRef.current?.setOpen(false)
		inputRef.current?.focus()
		calendarRef.current?.setFocus()
		calendarRef.current?.setOpen(true)
	}, [])

	const doBlur = useCallback(() => {
		calendarRef.current?.setOpen(false)
		calendarRef.current?.setBlur()
		inputRef.current?.blur()
	}, [])

	useImperativeHandle(ref, () => ({
		focus: () => doFocus(),
		blur: () => doBlur(),
		hasFocus: () => Boolean(calendarRef.current?.isCalendarOpen()),
		setSelectedDateFromDate: (v: Date) => {
			selectDate({ date: v })
		},
	}))

	// compute value & defaultValue from either string or Date objects
	const value = useMemo(() => {
		if ((pValue as unknown) instanceof Date) return pValue
		if (typeof pValue === "string" && !isEmpty(pValue)) {
			if (partial && pValue.includes("T") && pValue.length > 12) return new Date(pValue)
			if (partial) {
				// re-compute a valid date from partial date
				const now = new Date()
				const minutesOffset = now.getTimezoneOffset()
				const hoursOffset = Math.floor(minutesOffset / 60)
				const hoursStr = `${`000${Math.abs(hoursOffset)}`.slice(-2)}`
				const minutesStr = `${`000${Math.abs(minutesOffset % 60)}`.slice(-2)}`
				const newStr = `${getCompletedPartialDate(pValue)}T${hoursStr}:${minutesStr}:00.000${
					minutesOffset < 0 ? "+" : "-"
				}${hoursStr}:${minutesStr}` // always set the partial date at 00:00 UTC for everybody, regardless of the timezone
				return new Date(newStr)
			}

			return parseISO(pValue)
		}
		return undefined
	}, [pValue, partial])

	const defaultValue = useMemo(() => {
		if ((pDefaultValue as unknown) instanceof Date) return pDefaultValue
		if (typeof pDefaultValue === "string" && !isEmpty(pDefaultValue)) {
			if (partial) return new Date(pDefaultValue)
			return parseISO(pDefaultValue)
		}
		return undefined
	}, [pDefaultValue, partial])

	const minDate = useMemo(() => (pMinDate ? new Date(pMinDate) : undefined), [pMinDate])
	const maxDate = useMemo(() => (pMaxDate ? new Date(pMaxDate) : undefined), [pMaxDate])

	// hook to process the actual selected date for the calendar
	useEffect(() => {
		if (value) setSelectedDate(new Date(value))
		else if (defaultValue) setSelectedDate(new Date(defaultValue))
		else if (selected) setSelectedDate(new Date(selected))
		else setSelectedDate(undefined)
	}, [value, defaultValue, selected])

	// hook to work on dynamic displayed format
	useEffect(() => {
		if (partial) {
			let newExpectedFormat: DateFormat | string | undefined = undefined
			if (value || defaultValue || selected) newExpectedFormat = dynamicFormat
			if (newExpectedFormat) {
				const { dateFormat, dateFormatPlaceholder } = getFormats({
					...props,
					expectedFormat: newExpectedFormat,
				})
				setDynamicFormat(dateFormat)
				setPlaceholder(dateFormatPlaceholder)
			}
		}
	}, [partial, value, defaultValue, selected, dynamicFormat, props])

	const onInputChange = useCallback(
		(value?: string) => {
			let newExpectedFormat: DateFormat | string | undefined
			if (partial && value && typeof value === "string") {
				newExpectedFormat = dateFormat
				const splitted = value.split(separator)
				if (splitted.length === 2) newExpectedFormat = DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH
				else if (splitted.length === 1) newExpectedFormat = DateFormatsRevampedEnum.PARTIAL_DATE_YEAR
			}
			if (partial && value === "") newExpectedFormat = dateFormat
			if (newExpectedFormat) {
				const { dateFormat, dateFormatPlaceholder } = getFormats({
					...props,
					expectedFormat: newExpectedFormat,
				})
				setDynamicFormat(dateFormat)
				setPlaceholder(dateFormatPlaceholder)
			}
		},
		[props, partial, separator, dateFormat],
	)

	const displayKindProps: Partial<DatePickerProps> = {
		showYearDropdown: true,
		showMonthDropdown: true,
		showTimeSelectOnly: false,
	}
	if (partial) {
		if (PARTIAL_DATE_YEAR_FORMATS.includes(dynamicFormat)) displayKindProps.showYearPicker = true
		else if (PARTIAL_DATE_YEAR_MONTH_FORMATS.includes(dynamicFormat)) displayKindProps.showMonthYearPicker = true
	} else if (timeWithoutTZ) displayKindProps.showTimeSelectOnly = true

	const showTimeSelect = useMemo(() => {
		let showtimeSelect = pShowtimeSelect
		if (partial) showtimeSelect = false
		else if (
			originalDateFormat.includes("H:") ||
			originalDateFormat.includes("h") ||
			originalDateFormat.includes("m:") ||
			(originalDateFormat.includes("s") && pShowtimeSelect !== false)
		)
			showtimeSelect = true
		return showtimeSelect
	}, [pShowtimeSelect, partial, originalDateFormat])

	let nowBtnLabel = "Today"
	if (showTimeSelect) nowBtnLabel = "Now"
	else if (displayKindProps.showYearPicker) nowBtnLabel = "Current year"
	else if (displayKindProps.showMonthYearPicker) nowBtnLabel = "Current month"

	const selectDate = useCallback(
		({
			date,
			event,
			withBlur = true,
			withFocus = false,
		}: {
			date: Date | null | undefined
			// biome-ignore lint/suspicious/noExplicitAny: took from the lib
			event?: SyntheticEvent<any, Event> | undefined
			withBlur?: boolean
			withFocus?: boolean
		}) => {
			const fv = (transformer?.(date) as Date | string) ?? date
			setSelectedDate(date)
			onChange?.(fv as R extends string ? ISODatePickerReturn : OriginalDatePickerReturn, event)
			if (withBlur) doBlur()
			if (withFocus) doFocus()
		},
		[transformer, onChange, doBlur, doFocus],
	)

	// disable now button if outside of interval
	const nowBtnEnabled = useMemo(() => {
		let enabled = true
		// todo: fix issues of partial dates + min/max dates (today button is disabled even for the day corresponding to the maxDate)
		if (minDate) enabled = minDate.toISOString() <= new Date().toISOString()
		if (maxDate) enabled = new Date().toISOString() <= maxDate.toISOString()
		return enabled
	}, [minDate, maxDate])

	return (
		<DatePicker
			popperPlacement="bottom-start"
			{...rest}
			selectsMultiple={false as never} // type is messed-up in lib there
			selectsRange={false as never} // type is messed-up in lib there
			excludeScrollbar={false}
			ref={calendarRef}
			minDate={minDate}
			maxDate={maxDate}
			calendarClassName={s.datePickerRevamped}
			isClearable={isClearable}
			shouldCloseOnSelect={true}
			placeholderText={placeholder}
			selected={selectedDate}
			{...(selectedDate ? { openToDate: selectedDate } : {})}
			showTimeSelect={showTimeSelect}
			showTimeInput={showTimeSelect}
			showWeekNumbers={true}
			locale={locale}
			timeFormat="HH:mm"
			timeCaption="time"
			timeIntervals={15}
			calendarStartDay={1}
			wrapperClassName="w-full"
			disabledKeyboardNavigation={false}
			dateFormat={dynamicFormat}
			customInput={
				<DatePickerRevampedCustomInput
					inputRef={inputRef}
					dateFormat={dateFormat}
					placeholder={placeholder}
					autoFormat={autoFormat}
					open={open}
					doFocus={doFocus}
					className={clsx({
						"ring-1 ring-offset-2 ring-opacity-50 ring-red-400 rounded border-none": invalid,
					})}
				/>
			}
			popperContainer={DatePickerRevampedPopperContainer}
			onChangeRaw={(e) => {
				// only support changes from input element
				if (e?.target && "value" in e.target) return onInputChange(e.target.value as string)
			}}
			onKeyDown={(e) => {
				// todo: fix an issue with deletion of the day of day+month part
				if (e.key === "Enter" && changedValue) selectDate({ date: changedValue })
			}}
			onCalendarOpen={() => setOpen(true)}
			onCalendarClose={() => setOpen(false)}
			onSelect={(d, e) => {
				setChangedValue(d)
				selectDate({ date: d, event: e })
			}}
			onChange={(
				d: Date | [Date | null, Date | null] | Date[] | null,
				e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
			) => {
				if (Array.isArray(d)) throw new Error("DatePickerRevamped is not compatible yet with ranges or multiples selects")
				setChangedValue(d)
				const v = e?.target && "value" in e.target ? (e?.target?.value as string) : ""
				if (!v && d instanceof Date) return selectDate({ date: d, event: e })
				if (v && v.length === originalDateFormat.length)
					return selectDate({ date: d, event: e, withBlur: autoCloseOnComplete })
				if (v === "") return selectDate({ date: d, event: e, withFocus: true, withBlur: false })
			}}
			showPopperArrow={false}
			strictParsing
			peekNextMonth
			dropdownMode="select"
			{...displayKindProps}
		>
			<div className="pb-1 pt-2">
				<IconButton onClick={(e) => selectDate({ date: new Date(), event: e })} disabled={!nowBtnEnabled} className="pr-2">
					<CalendarIcon />
					{nowBtnLabel}
				</IconButton>
			</div>
		</DatePicker>
	)
}

// typing is asserted there as there are some complications to type with forwardRef + generics props
export const DatePickerRevamped = forwardRef(_DatePickerRevamped) as <R extends Date | string = string>(
	props: DatePickerRevampedProps<R> & { ref?: ForwardedRef<DatePickerRevampedHTMLInputRef> },
) => ReturnType<typeof _DatePickerRevamped>
